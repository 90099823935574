/**
* Dev, based on $env (from gulp)
*/
$env: 'development' !default;

/**
 * Colors
 * for the naming use http://chir.ag/projects/name-that-color/
 * from darker to lighter ?
 */

// Global colors
$c-black: #000;
$c-gray-darkest: #202020;
$c-gray-darker: #717171;
$c-gray-dark: #8d8d8d;
$c-gray-medium: #c8c8c8;
$c-gray-light: #dfdfdf;
$c-gray-lighter: #ececec;
$c-gray-lightest: #fcfcfc;
$c-white: #fff;
$c-transparent: transparent;

// Dev colors
$c-dev-bg: #3e4b55;
$c-dev-success: #090;
$c-dev-error: #d90b0b;
$c-dev-warning: #f50;
$c-dev-info: #829deb;
$c-dev: $c-dev-warning;

// Project colors
$c-main-orange: #ff4b00;
$c-main-border: #5e5e5e;
$c-footer-bg: #141414;
$c-topic: $c-gray-medium;

// UITP Colors

// Main Colors
$c-green-dark: #004047;
$c-green-light: #68bfac;
$c-yellow-medium: #fbb024;

//Secondary Colors
$c-orange-medium: #f5833b;
$c-green-medium: #5abc48;
$c-red-medium: #e84134;
$c-blue-medium: #1f72cc;
$c-pink-dark: #831240;
$c-green-dark: #004046;

// Sessions colors
$c-session-parralel: #68bfac;
$c-session-plenary: #e84134;
$c-session-networking: #e84134;
$c-session-visits: #e8e8e8;
$c-session-poster: #f07f31;
$c-session-workshop: #73ba59;
$c-session-default: $c-gray-dark;

// Universe Colors
$news: 'news';
$video: 'video';
$publication: 'publication';
$training: 'training';
$press: 'press';
$biography: 'biography';
$biographies: 'biographies';
$campaign: 'campaign';
$project: 'project';
$event: 'event';
$gallery: 'gallery';
$infography: 'infography';
$members: 'members-press-release';
$release: 'press-release';
$kit: 'press-kit';
$media: 'media';
$get-colors: (
  $news: $c-green-dark,
  $video: $c-green-light,
  $publication: $c-green-light,
  $training: $c-green-light,
  $press: $c-green-light,
  $biography: $c-yellow-medium,
  $biographies: $c-yellow-medium,
  $campaign: $c-orange-medium,
  $project: $c-green-medium,
  $event: $c-red-medium,
  $gallery: $c-blue-medium,
  $infography: $c-pink-dark,
  $kit: $c-green-light,
  $members: $c-green-light,
  $release: $c-green-light,
  $media: $c-topic,
);

/**
* Typography
*/

$base-font-size: 10px !default;

$ff-default: 'open-sans', sans-serif;
$ff-alt: 'century', sans-serif;
$body-text-s: 1.5em;
$body-text-m: 1.6em;
$body-text-l: 1.8em;

$font-sizes: (
  h1: 3.6rem,
  h2: 2.8rem,
  h3: 2.4rem,
  h4: 1.4rem,
);

/**
* RWD
*/
$mq-responsive: true;
$mq-static-breakpoint: 'large';

/*
 * Layout & spacing
 */

$spacing: 2rem;
$wrapper-width: get-breakpoint('xxl');
$columns-number: 12;
$header-height: 5rem;
$border-radius: 8px;

$spacings-map: (
  xxl: (
    l: 160px,
    m: 120px,
    s: 80px,
  ),
  xl: (
    l: 120px,
    m: 80px,
    s: 60px,
  ),
  l: (
    l: 80px,
    m: 60px,
    s: 40px,
  ),
  m: (
    l: 60px,
    m: 40px,
    s: 30px,
  ),
  s: (
    l: 40px,
    m: 30px,
    s: 20px,
  ),
  xs: (
    l: 20px,
    m: 15px,
    s: 10px,
  ),
  xxs: (
    l: 10px,
    m: 10px,
    s: 10px,
  ),
);

// $content-width: 144rem;

// Wrapper
$wrapper-spacing: col(1, 16);
$wrapper-width-s: get-breakpoint('s');
$wrapper-width-m: get-breakpoint('m');
$wrapper-width-l: get-breakpoint('l'); // getting wrong values from those!
$wrapper-width-xl: $wrapper-width;
