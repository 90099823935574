/// Mixin to customize scrollbars
/// Beware, this does not work in all browsers
/// @author Hugo Giraudel
/// @param {Length} $size - Horizontal height and vertical width
/// @param {Color} $foreground-color - color
/// @param {Color} $background-color [mix($foreground-color, white, 50%)] - color
/// @example scss - Scrollbar styling
///   @include scrollbars(.5em, slategray);
@mixin scrollbars(
  $size: 6px,
  $foreground-color: $c-black,
  $background-color: $c-white
) {
  // sass-lint:disable-block no-vendor-prefixes
  // For Google Chrome
  &::-webkit-scrollbar {
    position: absolute;
    width: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  // For Internet Explorer
  body {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}

@mixin get-all-space {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@mixin aspect-ratio($width, $height) {
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }

  > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

@mixin image-fit($fit: cover, $position: null) {
  @include get-all-space;

  object-fit: $fit;

  @if $position {
    font-family: 'object-fit: #{$fit}; object-position: #{$position}';
    object-position: $position;
  } @else {
    font-family: 'object-fit: #{$fit}';
  }
}

@mixin owl($margin: false) {
  > :first-child {
    margin-top: 0;

    @if $margin {
      margin-bottom: $margin;
    }
  }

  > :last-child {
    margin-bottom: 0;
  }

  > * + * {
    margin-top: 0;

    @if $margin {
      margin-bottom: $margin;
    }
  }
}

@mixin get-color {
  @each $class, $color in $get-colors {
    &.#{$class} {
      background-color: $color;
    }
  }
}

@mixin font-color {
  @each $class, $color in $get-colors {
    &.#{$class} {
      color: $color;
    }
  }
}

@mixin center-x {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@mixin center-y {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin center-xy {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin default-margin($coef: 1) {
  margin-top: $spacing * 1.5 * $coef;

  @include mq(m) {
    margin-top: $spacing * 4 * $coef;
  }
}

@mixin bottom-dashed-line($color: $c-gray-light) {
  background-image: linear-gradient(to right, $color 25%, transparent 0%);
  background-position: bottom;
  background-repeat: repeat-x;
  background-size: 10px 2px;
}

@mixin top-dashed-line($color: $c-gray-light) {
  background-image: linear-gradient(to right, $color 25%, transparent 0%);
  background-position: top;
  background-repeat: repeat-x;
  background-size: 10px 2px;
}
