
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        







































.jobs-categories {
  @include default-margin;

  position: relative;
}

.jobs-categories__item {
  width: col(14);
  margin: 0 col(1);

  & + & {
    margin-top: $spacing * 2;

    @include mq(m) {
      margin-top: $spacing * 3;
    }

    @include mq(l) {
      margin-top: $spacing * 4;
    }
  }

  @include mq(m) {
    display: flex;
    justify-content: space-between;
  }
}

.jobs-categories__item__title {
  color: $c-gray-lighter;

  @include mq(m) {
    width: col(6);
  }
}

.jobs-categories__jobs {
  @include mq(m) {
    width: col(8);
  }
}

.jobs-categories__job {
  @include bottom-dashed-line;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $spacing 0;
}

.jobs-categories__job__title {
  @extend %fw-bold;

  width: 60%;
}

.jobs-categories__job__link {
  position: relative;
  display: inline-flex;
  width: fit-content;
  color: $c-green-light;
  font-family: $ff-alt;
  font-weight: 900;
  text-decoration: none;

  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background-color: $c-green-light;
    transform-origin: left;
    transform: scaleX(1);
    transition: transform 0.3s $ease-out-quad;
  }

  &:hover,
  &:focus {
    background-size: 100% 1px;

    &::before {
      transform-origin: right;
      transform: scaleX(0);
    }
  }

  & + & {
    margin-left: $spacing / 2;
  }
}
